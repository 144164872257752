import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import PfIcon from './assets/icons/PfIcon';
import PfIconRev from './assets/icons/PfIconRev';
import SpotifyIcon from './assets/icons/SpotifyIcon';
import AppleIcon from '@mui/icons-material/Apple';
import { IconButton, Tooltip } from '@mui/material';

const INITIAL_YOUTUBE_VIDEO_POSITION = 13; // Thanos 6 - Freq Shop October 2024

function Home() {
  const { t } = useTranslation();
  const youtubeContentCarousel = [
    // 0 - Breaking Bad - Casa Del Popolo May 2024
    'JgSgu-i08U8',
    // 1 - Namor - Casa Del Popolo May 2024
    'P-uaKcg4UtU',
    // 2 - Red Rum - Casa Del Popolo May 2024
    'ghMhWDFonkY',
    // 3 - Broken Clouds - Casa Del Popolo May 2024
    'Z_3oWEN90lM',
    // 4 - Broken Clouds Visualizer
    'BGNUMQ2XTKU',
    // 5 - Thanos 1 + 2 Visualizer
    '6hX2KG9OFCY',
    // 6 - Thanos 3 Visualizer
    'gqUnt-_UXyc',
    // 7 - Thanos 4 Visualizer
    'ymGmzQj4_G8',
    // 8 - Thanos 5 Visualizer
    'HOhp9_D0gM8',
    // 9 - Thanos 6 Visualizer
    't5ptPdXS5Zs',
    // 10 - Breaking Bad Visualizer
    'M9S8LsqPSCM',
    // 11 - Lullaby in V Visualizer
    'gKkjkgTkX9U',
    // 12 - Namor Visualizer
    'zxIUMRQ9u90',
    // 13 - Thanos 6 - Freq Shop October 2024
    'edSXG8aRcdw'
  ];
  const [
    youtubeContentCarouselPosition,
    setYoutubeCountentCarouselPosition
  ] = useState(INITIAL_YOUTUBE_VIDEO_POSITION);

  const [playerBeyondReady, setPlayerBeyondReady] = useState(false);

  const youtubeIframeRef = useRef(null);
  const youtubePlayerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    script.async = true;
    script.onload = () => {
      // Initialize the YouTube player once the API script is loaded
      window.onYouTubeIframeAPIReady = () => {
        initializePlayer();
      };
    };
    document.body.appendChild(script);

    // Cleanup the YouTube API script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  });

  const handleOnPlayerReady = (event) => {
    if (playerBeyondReady) {
      event.target.playVideo();
    } else {
      setPlayerBeyondReady(true);
    }
  }

  const initializePlayer = () => {
    if (youtubeIframeRef.current && window.YT) {
      youtubePlayerRef.current = new window.YT.Player(youtubeIframeRef.current, {
        videoId: youtubeContentCarousel[youtubeContentCarouselPosition],
        events: {
          onStateChange: handleYouTubeStateChange,
          onReady: handleOnPlayerReady,
        },
      });
    } else {
      console.error('Youtube not ready');
    }
  }

  const contentCarousel = (direction) => {
    if (direction) {
      if (youtubeContentCarouselPosition < youtubeContentCarousel.length - 1) {
        setYoutubeCountentCarouselPosition(prevPos => prevPos + 1);
      } else {
        setYoutubeCountentCarouselPosition(0);
      }
    }
    else {
      if (youtubeContentCarouselPosition === 0) {
        setYoutubeCountentCarouselPosition(youtubeContentCarousel.length - 1);
      } else {
        setYoutubeCountentCarouselPosition(prevPos => prevPos - 1);
      }
    }
  };

  const getYoutubeIframeSource = () => {
    return `https://www.youtube.com/embed/${youtubeContentCarousel[youtubeContentCarouselPosition]}?enablejsapi=1`;
  }

  const handleYouTubeStateChange = (event) => {
    // -1 – unstarted
    //  0 – ended
    //  1 – playing
    //  2 – paused
    //  3 – buffering
    //  5 – video cued
    if (event.data === 0) {
      contentCarousel(true);
    }
  }

  return (
    <Container disableGutters={true} maxWidth={false} className='container'>
      <Box>
        <Box className='banner'>
          <span>
          <MuiLink href='https://open.spotify.com/artist/4WQRHx9X2yrzDSTkbKUvTE?si=edPFNsoPQwmAmXmvyW1QqA' target='_blank' className='noDeco'>
            <SpotifyIcon className='noDeco' />
          </MuiLink>
          <Link to='/music' className='noDeco'>
            <h3 className='captionIconSpan'> {t('home.new_music')} <PfIcon /> {t('music.available_now')} </h3>
          </Link>
          <MuiLink href='https://music.apple.com/us/artist/push-forward/1746204663' target='_blank' className='noDeco'>
            <AppleIcon className='noDeco' />
          </MuiLink>
          </span>
          
        </Box>
      </Box>

      <Box className="center">
        <Tooltip arrow title={t('navigation.previous')}>
         <IconButton className="youtubeCarrouselButtonL" edge='end' onClick={() => contentCarousel(false)} color="error" size='large'><PfIconRev fontSize="large"/></IconButton>
        </Tooltip>
          <iframe
            ref={youtubeIframeRef}
            id="YouTubeContent"
            width="560"
            height="315"
            src={getYoutubeIframeSource()} 
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;" 
            frameBorder="0"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            onLoad={initializePlayer}
          >
          </iframe>
        <Tooltip arrow title={t('navigation.next')}>
          <IconButton className="youtubeCarrouselButtonR" edge='start' onClick={() => contentCarousel(true)} color="error" size='large'><PfIcon fontSize="large" /></IconButton>
        </Tooltip>
      </Box>

      <Box>
          <Box className='banner'>
            <a href='https://www.facebook.com/share/1DqwRs6mL9/' rel="noreferrer" target='_blank' className='noDeco'>
              <span>
              <h3>{t('home.upcoming_escogriffe_420')}</h3>
              </span>
            </a>
          </Box>
      </Box>

      <Box className='bgimg5'>
        <Box className='caption'>
          <a href='https://www.instagram.com/pushforward.online' rel="noreferrer" target='_blank' className='noDeco'>
            <InstagramIcon className='noDeco bannerIcon' />
            <span className='noDeco bannerMessage'>{t('home.follow_insta')}</span>
          </a>
        </Box>
      </Box>

      <Box>
        <Box className='banner'>
          <span>
          <MuiLink href='https://open.spotify.com/artist/4WQRHx9X2yrzDSTkbKUvTE?si=edPFNsoPQwmAmXmvyW1QqA' target='_blank' className='noDeco'>
            <SpotifyIcon className='noDeco' />
          </MuiLink>
          <Link to='/music' className='noDeco'>
            <h3 className='captionIconSpan'> {t('home.new_music')} <PfIcon /> {t('music.available_now')} </h3>
          </Link>
          <MuiLink href='https://music.apple.com/us/artist/push-forward/1746204663' target='_blank' className='noDeco'>
            <AppleIcon className='noDeco' />
          </MuiLink>
          </span>
          
        </Box>
      </Box>

      <Box className='bgimg4'>
        <Box className='caption'>
          <a href='https://www.facebook.com/profile.php?id=61558005543750' rel="noreferrer" target='_blank' className='noDeco'>
            <FacebookIcon className='noDeco bannerIconBlue' />
            <span className='noDeco bannerMessage'>{t('home.like_fb')}</span>
          </a>

        </Box>
      </Box>

      <Box>
        <Box className='banner'>
          <Link to='/store' className='noDeco'>
            <h3> {t('home.merch')} <PfIcon /> {t('home.available_at_show')} </h3>
          </Link>
        </Box>
      </Box>

      <Box className='bgimg1'>
        <Box className='caption'>
          <Link to='/about' className='noDecoB'>
            <PfIcon className='noDecoB bannerIconB' />
            <span className='noDecoB bannerMessageB'>
              {t('home.learn_more_about')}
            </span>
          </Link>
        </Box>
      </Box>

    </Container>
  );
}

export default Home;
